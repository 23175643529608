p,
h1,
h2 {
  margin: 0px;
  font-family: "Kumbh Sans", sans-serif;
}

p {
  line-height: 1.5;
}

input {
  outline: none;
}

a {
  font-size: 8px;
  color: white;
}

#header {
  background-color: white;
}

footer {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  margin: 1em 0em;
}

#privacy {
  margin: 6em;
}

#app-stores{
  display: flex;
  flex-direction: column;
  margin-top: 1em;
  margin-bottom: 0.5em;
}

@media (min-width: 120px) {

  #coming-soon #ai-graphic-text {
    margin: 3em 3em 0em 3em;
    /* position: absolute; */
    /* top: 3em; */
    /* z-index: 1; */
  }


  iframe {
    height: 75vh;
    width: 100vw;
    align-self: center;
  }

  #Account h1 {
    font-size: 64px;
  }

  .Center-Container {
    display: flex;
    flex-direction: row;
    height: 80vh;
    background-color: #1a1b1a;
  }

  .Center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 80vh;
    width: 50vw;
    background-color: #1a1b1a;
  }

  #Account h2 {
    color: white;
    font-size: 36px;
    margin-bottom: 0.5em;
  }

  .Center p, label {
    color: white;
    font-size: 16px;
    margin-bottom: 1em;
  }

  .Error-Message p {
    color: #e08e8e;
    padding: 0em 0em 0em 0em;
  }

  .Success-Dialog p {
    color: #4aa87a;
  }

  .Success-Dialog  {
    color: #4aa87a;
  }

  .Signup-Link {
    color: white;
    font-size: 16px;
  }

  .Center button {
    /* height: 100%; */
    background-color: #e08e8e;
    color: white;
    border: transparent;
    font-weight: 400;
    border-radius: 0.5em;
    transition-duration: 1s;
    padding: 0.5em;
    font-size: 16px;
    margin-bottom: 1em;
  }

  .Center button:hover {
    transition-duration: 0.5s;
    background-color: #4aa87a;
  }

  .Center button:active {
    transition-duration: 0.5s;
    background-color: #40916a;
  }

  .Center h1 {
    color: white;
    margin-bottom: 1em;
    font-size: 52px;
  }

  .Center input {
    border-radius: 0.5em;
    padding: 0.5em;
    margin-bottom: 1em;
    border: transparent;
    font-size: 16px;
  }

  #fourth-section h1 {
    font-size: 36px;
    color: white;
    padding: 0em 0em 0.5em 0em;
    align-self: center;
  }

  #light-desc h1 {
    font-size: 36px;
    color: white;
    padding: 1em 0em 1em 0em;
  }

  #newsletter button {
    background-color: #e08e8e;
    height: 2.5em;
    width: 2.5em;
    border-radius: 1.5em;
    border: none;
    float: right;
    color: white;
    font-size: 1.5em;
  }

  .newsletter-image {
    overflow: hidden;
    width: 90%;
    height: 30vh;
    margin: 2.5em 0em;
  }

  .newsletter-entry {
    background-color: white;
    padding: 2em;
    margin: 0em 1em;
    border-radius: 1em;
    border: none;
    width: 90vw;
    height: 100%;
    align-items: center;
    flex-direction: column;
    display: flex;
    transition-duration: 1s;
  }

  .newsletter-entry:hover {
    background-color: #dfeaff;
    transition-duration: 0.5s;
  }

  .newsletter-entry:active {
    background-color: #cbdeff;
    transition-duration: 0.5s;
  }

  .newsletter-entry img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  #newsletter-entries {
    margin: 2em 0em;
    padding: 2em 0em;
    width: 100vw;
    overflow: scroll;
    background-color: #ececec;
    display: flex;
    flex-direction: row;
  }

  #news .newsletter-entry h1 {
    font-size: 24px;
    color: black;
  }

  #newsletter-body {
    padding: 5em 0em 0em 10em;
  }

  #newsletter {
    margin: 2em 0em 2em 0em;
    background-color: white;
    width: 100vw;
  }

  #newsletter-image {
    height: 40vh;
    width: 30vw;
    float: right;
    margin-left: 3.5em;
    margin-right: 10em;
    margin-bottom: 5em;
  }

  #newsletter-image img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  #news #newsletter h1 {
    font-size: 24px;
  }

  #news-signup {
    color: white;
    margin-bottom: 1em;
  }

  #news {
    background-color: #6254ba;
  }

  #news h1 {
    font-size: 64px;
    color: white;
  }

  #news-body {
    display: flex;
    padding: 3em 0em 7em 0em;
    align-items: center;
    flex-direction: column;
  }

  #newsletter p,
  #newsletter h1,
  #newsletter h2 {
    color: black;
  }

  #footer-socials {
    height: 5em;
    width: 80%;
  }

  #pitch-stats {
    padding: 2em 2.5em 0em 2.5em;
  }

  #pitch-footer {
    padding: 0em 2.5em 0em 2.5em;
  }

  #first-signup {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 15em;
  }

  #ai-page p,
  #ai-page h2 {
    color: white;
  }

  #demo-page p,
  #demo-page h2 {
    color: white;
  }

  #footer-routes {
    display: flex;
    flex-direction: column;
    padding: 0.5em 0em 5em 0em;
  }

  #footer-routes h2 {
    margin: 2em 0em 0.5em 0em;
  }

  #footer-routes button {
    font-size: 18px;
    border: transparent;
    color: black;
    background-color: transparent;
    font-family: "Kumbh Sans", sans-serif;
    font-weight: 300;
    padding: 0.5em 0em 0.5em 0em;
    width: 10em;
    text-align: left;
  }

  #demo-second p {
    padding: 2em 0em;
  }

  #fourth-section a {
    margin-top: 5em;
    display: flex;
    justify-content: center;
  }

  #mobile-home-demo {
    background-color: #e08e8e;
    color: white;
    font-family: "Kumbh Sans", sans-serif;
    font-weight: 900;
    border: transparent;
    border-radius: 1.5em;
    padding: 1em;
  }

  #mobile-home-demo:hover {
    transition-duration: 0.5s;
    background-color: #4aa87a;
  }

  #mobile-home-demo:active {
    transition-duration: 0.5s;
    background-color: #40916a;
  }

  #footer-routes button:hover {
    transition-duration: 0.5s;
    color: #5a82ce;
  }

  #footer-routes button:active {
    color: black;
  }

  #fourth-left {
    display: flex;
    flex-direction: column;
  }

  #fourth-left .platform-img {
    visibility: hidden;
    height: 0;
    margin: 0;
  }

  #fourth-section #fourth-left .platform-img {
    visibility: visible;
    height: auto;
  }

  #fourth-right {
    display: flex;
    flex-direction: column;
  }

  #home-footer h2 {
    color: black;
    font-size: 16px;
  }

  #home-footer p {
    font-size: 14px;
    padding: 0.5em 0em;
    word-wrap: break-word;
  }

  .footer-spaced {
    padding-top: 1em;
  }

  #footer-disclaimer {
    /* width: 60vw; */
  }

  #footer-disclaimer a {
    color: black;
    font-size: 16px;
  }

  #address {
    margin-top: 3em;
    display: flex;
    flex-direction: column;
  }

  #home-footer {
    display: flex;
    flex-direction: column;
    padding: 5vw 10vw;
    justify-content: space-between;
  }

  #home-footer p {
    color: #535653;
  }

  #disclaimer {
    font-size: 8px;
    margin-bottom: 1em;
    color: white;
  }

  #ai-info-text {
    margin-bottom: 3em;
  }
  #ai-info-text p {
    margin-bottom: 2em;
  }

  li {
    color: white;
    margin-bottom: 1em;
    font-family: "Kumbh Sans", sans-serif;
  }

  #indiv-light-desc {
    transition-duration: 0.5s;
    opacity: 1;
    align-items: center;
    display: flex;
    /* text-align: center; */
    z-index: -1;
  }

  #header-right {
    display: none;
  }

  /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  #header-right,
  #header-right-sm button {
    font-size: 18px;
    border: transparent;
    color: black;
    background-color: transparent;
    font-family: "Kumbh Sans", sans-serif;
    font-weight: 900;
    padding: 0.5em 0.5em;
  }

  #header-right-sm button{
    width: 70vw;
    text-align: left;
  }

  #header-left h1 {
    padding: 0.25em;
    font-size: 1em;
  }

  #header-right button:hover,
  #header-right-sm button:hover {
    transition-duration: 0.5s;
    color: #5a82ce;
  }

  #header-right button:active,
  #header-right-sm button:active {
    color: black;
  }

  #contact-page {
    background-color: #1a1b1a;
    min-height: 100vh;
  }

  #contact-first {
    margin-bottom: 5em;
  }

  #contact-first h2 {
    margin-bottom: 0.5em;
  }

  #name-inputs {
    width: 100%;
  }

  #name-inputs input {
    width: 30%;
  }

  #contact-second {
    width: 100%;
  }

  #contact-second textarea {
    border: none;
    width: -webkit-fill-available;
    border-radius: 0.5em;
    margin-bottom: 1em;
    padding: 0.5em;
    height: 10vh;
  }

  #contact-submit {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  #contact-second button {
    height: 100%;
    width: 50%;
    background-color: rgb(224, 142, 142);
    color: white;
    border: transparent;
    border-radius: 0.5em;
    padding: 1em;
  }

  #contact-second button:hover {
    transition-duration: 0.5s;
    background-color: #4aa87a;
  }

  #contact-second button:active {
    transition-duration: 0.5s;
    background-color: #40916a;
  }

  #contact-second input {
    border-radius: 0.5em;
    width: -webkit-fill-available;
    padding: 0.5em;
    margin-bottom: 1em;
    border: transparent;
  }

  #contact {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 5em 5em;
  }

  #ai-page {
    min-height: 100vh;
    width: 100vw;
  }

  #ai-graphic-text h2 {
    font-size: 36px;
    margin-bottom: 0.5em;
  }

  #ai-info {
    background-image: linear-gradient(to bottom, #6254ba, #e08e8e);
    overflow: hidden;
  }

  #ai {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    padding: 5em 3em 0em 3em;
    background-color: #1a1b1a;
  }

  #ai-info {
    padding: 5em 5em;
  }

  #ai-graphic-text {
    z-index: 2;
    padding-bottom: 3em;
    width: 100%;
  }

  #ai-graphic {
    width: 100vw;
    justify-content: flex-end;
    display: flex;
    position: absolute;
    z-index: 0;
  }

  #main-screen-graphic img {
    width: 100%;
    height: 100%;
  }

  #main-screen-graphic {
    margin-left: 7em;
    height: 100%;
    width: 100vw;
    justify-content: center;
    z-index: 1;
  }

  #ai-graphic img {
    height: 100%;
    width: 100%;
    display: hidden;
  }

  #ai-info-graphic {
    height: 60vh;
    display: flex;
    justify-content: center;
  }

  #ai-info-graphic img {
    float: center;
    height: 100%;
  }

  #demo {
    padding: 5em 5em;
    align-items: center;
    display: flex;
    flex-direction: column;
    background-image: linear-gradient(to bottom, #6254ba, #4aa87a);
  }

  #demo h1 {
    padding-bottom: 0.5em;
  }

  #demo-page {
    min-height: 100vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }

  #demo-logo {
    height: 100px;
    margin-top: 3em;
  }

  #demo-logo img {
    height: 100%;
  }

  #demo h1,
  #demo h2,
  #demo p {
    color: white;
  }

  #Account {
    padding: 5em 5em;
    display: flex;
    flex-direction: column;
    height: 100%;
    color: white;
    background-color: #1a1b1a;
    align-items: center;
    text-align: center;
  }

  #demo-second {
    padding: 5em 5em;
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: #1a1b1a;
    align-items: center;
    text-align: center;
  }

  #Auth {
    padding: 5em 5em;
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: #1a1b1a;
    align-items: center;
    text-align: center;
  }

  hr {
    color: white;
    display: none;
    width: 75vw;
  }

  .platform-img {
    width: 100vw;
    margin-top: 2em;
    margin-right: 0.3em;
  }

  .platform-img img {
    width: 100%;
    border-radius: 2em;
  }

  #header {
    display: flex;
    flex-direction: column;
    padding: 16px 20px;
  }

  #header-left {
    font-size: 24px;
    font-family: "Kumbh Sans", sans-serif;
    color: black;
    padding: 0.25em;
    font-weight: 600;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  #menu-btn {
    border: none;
    height: 40px;
    width: 50px;
    margin-right: 0.5em;
    background-color: transparent;
  }

  #menu-btn img {
    height: 100%;
    width: 100%;
  }

  #header-right-sm {
    overflow: hidden;
    transition-duration: 0.5s;
    background-color: white;
    position: absolute;
    top: 4.25em;
    z-index: 2;
    display: flex;
    flex-direction: column;
    font-family: "Kumbh Sans", sans-serif;
    padding: 0.5em 1em 0.5em 1em;
    /* color: black; */
    border: none;
  }

  #pitch {
    position: absolute;
    /* transform: translateY(-115vh); */
    width: 100%;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    /* padding: 0em 2.5em 0em 2.5em; */
  }

  #pitch p {
    font-family: "Kumbh Sans", sans-serif;
    color: white;
    font-size: 14px;
    margin: 0.5em 0em;
  }

  #patent p {
    font-size: 10px;
  }

  .pitch-txt-lrg {
    font-family: "Kumbh Sans", sans-serif;
    font-style: bold;
    font-size: 24px;
    font-weight: 900;
    color: white;
  }

  #pitch-graphic {
    margin: 0em;
    /* height: 120vh; */
    /* background-color: #000000; */
    background-image: linear-gradient(to bottom, #6254ba, #e08e8e);
    overflow: hidden;
  }

  #pitch-graphic img {
    opacity: 0.6;
    height: 100%;
    transform: translateX(-10em);
  }

  #first-section {
    display: block;
  }

  #second-section {
    background-color: #1a1b1a;
    padding: 2em 0em;
    display: flex;
    overflow: hidden;
    flex-direction: column;
    align-items: center;
  }

  #home-mini-logo {
    padding: 2em 2em 0em 2em;
    width: 75vw;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  #home-mini-logo p {
    color: white;
    font-size: 16px;
  }

  #home-mini-logo img {
    height: 50px;
    margin-bottom: 3em;
  }

  #device-graphic {
    width: 100vw;
    display: flex;
    justify-content: center;
    padding: 2em 0em 2em 0em;
  }

  #device-graphic img {
    width: 95%;
  }

  #btn-row {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2em;
    /* justify-content: center; */
  }

  #third-section {
    background-color: #4aa87a;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  #overlay {
    z-index: 1;
    position: absolute;
    height: 50em;
    width: 100%;
    background-image: linear-gradient(to bottom, transparent 30%, #4aa87a 38%);
    align-self: flex-end;
  }

  #third-section p {
    color: white;
    font-size: 16px;
  }

  #fourth-section {
    background-color: #1a1b1a;
    display: flex;
    padding: 4em 3em;
    flex-direction: column;
    z-index: -1;
    position: relative;
  }

  #app-info-section {
    display: flex;
    flex-direction: column;
    /* padding: 4em 3em; */
  }

  #fourth-section p {
    color: white;
    font-size: 18px;
    width: 50%;
    margin-top: 5em;
  }

  #app-info-section p{
    color: white;
    font-size: 16px;
    /* width: 80%; */
  }

  #app-info-section h1{
    color: white;
    /* width: 80%; */
    padding-bottom: 1em;
  }

  .home-top-down-graphic {
    display: flex;
    justify-content: center;
    overflow: hidden;
    width: 100%;
    height: 20em;
  }

  .home-top-down-graphic img {
    rotate: 90deg;
    align-self: flex-start;
    float: left;
    /* position: absolute; */
    width: 250vw;
  }

  #light-desc {
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0em 3em;
    justify-content: flex-end;
  }

  #light-desc p {
    /* margin: 3em 3em 1em 3em; */
    font-family: "Kumbh Sans", sans-serif;
    font-size: 16px;
  }

  #indiv-light-desc p {
    /* margin: 1em 3em 2em 3em; */
  }

  #light-info-btns {
    z-index: 2;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
  }

  .info-btn {
    background-color: white;
    color: black;
    width: 20vw;
    height: 20vw;
    padding: 1em;
    margin: 5vw 5vw 5vw 0vw;
    border: transparent;
    border-radius: 1em;
    font-size: 30px;
    align-items: center;
    justify-content: center;
    display: flex;
  }

  .info-btn img {
    height: 30px;
  }

  .info-btn:hover {
    transition-duration: 0.25s;
    background-color: #e0e0e0;
  }

  .info-btn:active {
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.3);
    transition-duration: 0.1s;
    background-color: #c8c8c8;
  }

  #fifth-section {
    background-color: #5a82ce;
    display: flex;
    flex-direction: column;
    padding: 7.5em 0em;
    align-items: center;
  }

  #home-mid-logo {
    height: 150px;
  }

  #home-mid-logo img {
    height: 100%;
  }

  #fifth-section p {
    font-family: "Kumbh Sans", sans-serif;
    color: white;
    font-size: 16px;
  }

  #signup-caption {
    padding: 3em;
    text-align: center;
  }

  #landing-signup {
    justify-content: flex-end;
    display: flex;
    background-color: white;
    height: 2.5em;
    width: 70vw;
    border-radius: 0.5em;
  }

  #first-signup #landing-signup {
    background-color: white;
    height: 3em;
    width: 70vw;
    border-radius: 0.5em;
  }

  #landing-signup button {
    width: 30%;
    height: 100%;
    background-color: #e08e8e;
    color: white;
    border: transparent;
    font-weight: 400;
    border-radius: 0.5em;
    transition-duration: 1s;
  }

  #landing-signup button:hover {
    transition-duration: 0.5s;
    background-color: #4aa87a;
  }

  #landing-signup button:active {
    transition-duration: 0.5s;
    background-color: #40916a;
  }

  #landing-signup input {
    width: 80%;
    margin-left: 0.5em;
    border: transparent;
  }
}

@media (min-width: 480px) {
  /* smartphones, Android phones, landscape iPhone */

  #overlay {
    background-image: linear-gradient(to bottom, transparent 37%, #4aa87a 43%);
    height: 65em;
  }

  .home-top-down-graphic {
    height: 30em;
  }

  .info-btn {
    border-radius: 1.5em;
  }

  #light-info-btns {
    margin-top: 1em;
  }

  .info-btn img {
    height: 40px;
  }

  #name-inputs {
    justify-content: space-between;
    display: flex;
    flex-direction: row;
  }

  #name-inputs input {
    width: 45%;
  }

  .platform-img {
    width: 80%;
  }
}

@media (min-width: 600px) {
  #pitch-graphic img {
    transform: translateX(-50em);
  }
  .home-top-down-graphic {
    height: 35em;
  }
  #overlay {
    height: 70em;
    background-image: linear-gradient(to bottom, transparent 40%, #4aa87a 47%);
  }
  /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
  .info-btn img {
    height: 50px;
  }

  .info-btn {
    font-size: 46px;
    border-radius: 1em;
  }

  .platform-img {
    width: 70%;
  }

  .platform-img img {
    border-radius: 3em;
  }

  #contact-second {
    width: 40vw;
  }

  #name-inputs input {
    width: 40%;
  }

  .newsletter-entry {
    width: 30em;
  }

  .newsletter-image {
    height: 20em;
  }

  iframe {
    height: 48em;
    width: 26em;
    margin-top: 3em;
  }
}

@media (min-width: 801px) {
  #btn-row {
    margin-top: 0em;
    flex-direction: row;
  }

  #app-stores{
    flex-direction: row;
  }

  #main-screen-graphic {
    justify-content: flex-end;
    position: absolute;
    display: flex;
  }

  #main-screen-graphic img{
    width: unset;
  }
  
  #fourth {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  .sharethis-inline-share-buttons#st-1.st-justified {
    display: none;
  }

  #footer-socials {
    display: none;
  }
  #first-signup {
    margin-top: 1.5em;
  }

  #address {
    margin: 0em;
  }

  #home-footer {
    flex-direction: row;
  }

  #footer-disclaimer {
    width: 30vw;
  }

  #disclaimer {
    font-size: 12px;
  }

  #fourth-right {
    margin-top: 5em;
  }

  #overlay {
    height: 70em;
    background-image: linear-gradient(to bottom, transparent 43%, #4aa87a 53%);
  }

  .home-top-down-graphic {
    height: 40em;
  }

  /* tablet, landscape iPad, lo-res laptops ands desktops */
  #name-inputs input {
    width: 42%;
  }

  .info-btn img {
    height: 60px;
  }

  .info-btn {
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.3);
    font-size: 56px;
    border-radius: 1.25em;
  }

  #ai {
    display: flex;
    align-items: unset;
    min-height: 70vh;
    justify-content: flex-end;
    position: relative;
    overflow: hidden;
  }

  #ai-info-text p {
    font-size: 18px;
    margin-bottom: 2em;
  }

  #ai-info-text {
    margin: 5em 5em;
  }

  #ai-graphic {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    width: 100%;
  }

  #ai-graphic img {
    width: 80%;
    height: 80%;
  }

  #fourth-section h1 {
    padding: 0em 0em 2em 0em;
  }

  #ai-graphic-text {
    margin: 3em 0em 7em 3em;
    /* position: absolute; */
    /* top: 3em; */
    /* z-index: 1; */
    width: 40vw;
    /* height: 70vh; */
  }

  #ai-graphic-text h2 {
    font-size: 64px;
    margin-bottom: 0.5em;
  }

  #ai-graphic-text p {
    font-size: 24px;
  }

  #demo {
    padding: 5em 10em;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }

  #demo h1 {
    font-size: 64px;
  }

  #demo-logo {
    height: 500px;
  }

  #device-graphic {
    width: 60vw;
    padding: 5em 0em 3em 0em;
    overflow: hidden;
  }

  .platform-img {
    width: 60%;
  }

  .platform-img img {
    border-radius: 4em;
  }

  hr {
    display: block;
  }

  #device-graphic img {
    width: 100%;
  }

  #pitch-graphic {
    width: 50vw;
    max-height: 60%;
    background-image: none;
    /* margin: 0em 2.5em 0em 5em; */
    /* position: fixed; */
    overflow: visible;
    /* transform: translateX(16em); */
    background-color: transparent;
  }

  #pitch-graphic img {
    transform: none;
    width: 100%;
    height: unset;
    float: right;
    opacity: 1;
    /* transform: translateX(18em); */
  }

  #fourth-left .platform-img {
    visibility: visible;
    height: auto;
  }

  #pitch {
    background-image: linear-gradient(to bottom, #6254ba, #e08e8e);
    height: 100%;
    position: relative;
    transform: none;
  }

  #first-section {
    display: flex;
    height: 70vh;
    flex-direction: row-reverse;
    align-items: center;
  }

  #header {
    padding: 24px 76px;
    flex-direction: row;
    justify-content: space-between;
  }

  #menu-btn {
    display: none;
    height: 4em;
    width: 4.5em;
  }

  #header-left {
    font-size: 36px;
  }

  #header-right-sm {
    display: none;
    padding: 1em;
    background-color: transparent;
    position: unset;
    top: unset;
  }

  #header-right {
    padding: 1em;
    display: block;
    background-color: transparent;
    position: unset;
    top: unset;
  }

  #header-right button,
  #header-right-sm button {
    font-size: 26px;
    border: transparent;
    background-color: transparent;
    font-family: "Kumbh Sans", sans-serif;
    font-weight: 900;
    padding: 0.25em 0.5em;
  }

  #second-section {
    padding: 3em 0em;
    position: relative;
    z-index: 1;
  }

  #mobile-home-demo {
    padding: 1.5em;
    font-size: 2em;
  }

  #first-signup #landing-signup {
    width: 50vw;
  }

  #light-desc h1 {
    font-size: 66px;
  }

  #fourth-section h1 {
    font-size: 66px;
  }
  #ai-info h1 {
    font-size: 48px;
    color: white;
    margin-bottom: 1.5em;
  }
  #fourth-section a {
    margin-top: 12em;
  }

  #fourth-left p, #fourth-right p {
    font-size: 36px;
    width: 70%;
  }

  #coming-soon #ai-graphic-text {
    margin: 3em 3em 3em 3em;
    /* position: absolute; */
    /* top: 3em; */
    /* z-index: 1; */
    width: 60vw;
    height: unset;
  }
}

@media (min-width: 1025px) {
  #fourth {
    flex-direction: row;
  }

  #fourth-section p {
    font-size: 22px;
    width: 50%;
  }

  #first-signup {
    align-items: flex-start;
  }

  #fourth-section a {
    display: grid;
  }

  #mobile-home-demo {
    width: 30em;
    justify-self: center;
  }

  #demo-logo {
    margin: 3em 3em 0em 10em;
  }

  #demo-logo img {
    height: 70%;
  }

  /* big landscape tablets, laptops, and desktops */
  .info-btn img {
    height: 40px;
  }

  #name-inputs input {
    width: 45%;
  }

  #demo p {
    font-size: 24px;
  }

  #demo {
    padding: 5em 5em 0em 5em;
  }

  #demo-first {
    margin: 3em 0em 7em 3em;
  }
  #demo-second h2 {
    font-size: 58px;
  }

  .platform-img {
    width: 35vw;
    margin: -1em 0em 0em 0.5em;
  }

  .platform-img img {
    border-radius: 1em;
  }

  #header-right button {
    padding: 0.25em 1em;
  }

  #main-screen-graphic {
    margin-left: 20em;
    justify-content: center;
  }

  #home-mini-logo {
    padding: 5em 5em 0em 5em;
    width: 50vw;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  #home-mini-logo p {
    color: white;
    font-size: 22px;
  }

  #home-mini-logo img {
    height: 75px;
    margin-bottom: 3em;
  }

  #home-mid-logo {
    height: 250px;
  }

  #home-mid-logo img {
    height: 100%;
  }

  .home-top-down-graphic {
    float: right;
    width: 50%;
    justify-content: center;
  }

  .home-top-down-graphic img {
    rotate: 90deg;
    transform: (-50em, 50em);
    float: left;
    /* position: absolute; */
    width: 100vw;
    transition: opacity 0.5s ease-in-out;
    opacity: 1;
  }

  #light-desc {
    width: 50vw;
    margin-left: 5em;
    /* height: 55em; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  #light-desc p {
    font-family: "Kumbh Sans", sans-serif;
    font-size: 20px;
  }

  #pitch-graphic {
    width: 25vw;
    max-height: 60%;
  }

  #pitch-graphic p {
    margin-top: 40vh;
    color: black;
  }

  #light-desc h1 {
    margin-top: 0.5em;
  }

  #pitch {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background-image: linear-gradient(to bottom, #6254ba, #e08e8e);
    padding: 0em 5em 0em 5em;
    width: 65%;
  }

  #pitch p {
    font-family: "Kumbh Sans", sans-serif;
    font-size: 24px;
    line-height: 1.5;
    color: white;
  }

  #patent p {
    font-size: 14px;
  }

  .pitch-txt-lrg {
    font-family: "Kumbh Sans", sans-serif;
    font-size: 32px;
    font-style: bold;
    font-weight: 900;
    color: white;
  }

  #first-section {
    /* height: 60vh; */
    width: 100%;
  }

  #second-section {
    background-color: #1a1b1a;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0em 0em 5em 0em;
  }

  #third-section {
    background-color: #459b71;
    display: flex;
    flex-direction: row-reverse;
    overflow: hidden;
  }

  #overlay {
    background-image: linear-gradient(to bottom, transparent 60%, #4aa87a 88%);
    /* height: 55em; */
  }

  .home-top-down-graphic {
    height: 65em;
  }

  #third-section p {
    width: 33vw;
    color: white;
    font-size: 22px;
  }

  #fourth-section {
    background-color: #1a1b1a;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 8em 8em;
  }

  #app-info-section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* padding: 8em 8em; */
  }

  #fourth-left {
    align-items: center;
    flex: 1;
  }

  #light-info-btns {
    display: flex;
    /* margin-top: 2.5em; */
    /* flex-direction: column; */
  }

  .info-btn {
    background-color: white;
    color: black;
    width: 3em;
    height: 3em;
    padding: 2em;
    margin: 1em 1.5em 1em 0em;
    border: transparent;
    border-radius: 1.5em;
    font-size: 30px;
  }

  #fourth-right {
    margin: 7em 0em 0em 0em;
    align-items: center;
    justify-content: flex-start;
    flex: 1;
  }

  #app-info-section p{
    color: white;
    font-size: 20px;
    width: 20vw;
  }

  #fifth-section {
    background-color: #5a82ce;
    display: flex;
    flex-direction: column;
    padding: 7.5em 0em;
    align-items: center;
  }

  #fifth-section p {
    color: white;
    font-size: 20px;
  }

  #signup-caption {
    padding-bottom: 2em;
  }

  #landing-signup {
    background-color: white;
    height: 2.5em;
    width: 17.5em;
    border-radius: 0.5em;
  }

  #first-signup #landing-signup {
    background-color: white;
    height: 3em;
    width: 20em;
    border-radius: 0.5em;
  }

  #landing-signup button {
    width: 30%;
    height: 100%;
    background-color: #e08e8e;
    color: white;
    border: transparent;
    border-radius: 0.5em;
  }

  #landing-signup button:hover {
    transition-duration: 0.5s;
    background-color: #4aa87a;
  }

  #landing-signup button:active {
    transition-duration: 0.5s;
    background-color: #40916a;
  }

  #landing-signup input {
    width: 80%;
    margin-left: 0.5em;
    border: transparent;
  }
}

@media (min-width: 1281px) {
  #first-section {
    height: 60vh;
  }
  /* hi-res laptops and desktops */

  #second-section p,
  #fourth-section,
  #fifth-section,
  #third-section p {
    font-size: 18px;
  }

  #fourth-right {
    margin: 0em;
  }

  #home-footer h2 {
    font-size: 20px;
  }

  #home-footer p {
    font-size: 16px;
  }

  .platform-img {
    width: 30vw;
  }
  .platform-img img {
    border-radius: 2em;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
